import React from "react"

import Layout from "@src/components/Layout"
import Row from "../../V2/components/Row/Row"
import ImageRow from "../../V2/Features/components/ImageRow/ImageRow"
import Button from "../../../components/Button/Button"
import getAppUrl from "@src/utils/getAppUrl"
import DotBG from "../../V2/components/DotBG/DotBG"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm/CalloutWithDemoForm"
import Column from "../../V2/components/Column/Column"
import Splash from "../../V2/Features/components/Splash"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import HeaderImage from "@src/icons/industries/retail_header.png"
import CustomerExperience from "@src/icons/industries/retail_customer_exp.png"
import FasterTime from "@src/icons/industries/retail_faster_time.png"
import Streamline from "@src/icons/industries/retail_streamline.png"
import IncreasedRevenue from "@src/icons/industries/retail_increased_revenue.png"
import CardOne from "@src/icons/industries/retail_card_one.png"
import CardTwo from "@src/icons/industries/retail_card_two.png"
import CardThree from "@src/icons/industries/retail_card_three.png"
import OG from "@src/icons/og/OG_Retail.jpg"
import * as styles from "./Retail.module.scss"

const Retail = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()

  return (
    <Layout
      title="Boost Your Retail Business with Low-Code Development"
      description="Streamline Operations, Enhance Customer Experience, and Drive Business Success."
      url="https://canonic.dev/industries/retail"
      ogImage={OG}
      className={styles.retail}
    >
      <Splash
        title="Boost Your Retail Business with Low-Code Development"
        subtitle={
          "Streamline Operations, Enhance Customer Experience, and Drive Business Success."
        }
        illustration={<img src={HeaderImage} />}
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless marginLeft className={styles.retailCtaOutline}>
              Get a Demo
            </Button>
          </a>,
        ]}
        reverseOnMobile
        className={styles.retail}
      />
      <Row>
        <div className={styles.retailSolution}>
          <h2>Overcoming Retail Challenges</h2>
          <p>
            In the retail industry, there are several obstacles that stand in
            the way of success. Manual processes, legacy systems, and siloed
            data can create inefficiencies that hamper productivity and eat into
            profits. Furthermore, limited resources and the need to deliver
            exceptional customer experiences make it difficult for retailers to
            keep up with the pace of technological change. However, there is a
            solution: low-code.
          </p>
        </div>
      </Row>
      <section>
        <Row className={styles.retailProcess}>
          <h2>Revamping Retail Industry with Low-code</h2>
          <ImageRow
            title="Enhanced Customer Experience"
            subtitle={
              "Create personalized shopping experiences for customers, by integrating with their existing systems and allowing to quickly build new features like product recommendations, wishlists, real-time inventory tracking, etc."
            }
            screenshot={CustomerExperience}
            borderless
            className={styles.retailProcessSources}
          />
          <ImageRow
            title="Faster Time to Market"
            subtitle="Develop and deploy new applications to meet changing customer needs and market trends. This helps in staying ahead of the competition and improve bottom line."
            screenshot={FasterTime}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
          <ImageRow
            title={"Streamlined Operations: "}
            subtitle={
              "Automate routine tasks, such as inventory management and order fulfillment. This can free up employees to focus on more value-added tasks, such as customer service and product development."
            }
            screenshot={Streamline}
            borderless
            className={styles.retailTailorMade}
          />
          <ImageRow
            title="Increased Revenue"
            subtitle=" By streamlining operations and improving customer experience, low-code can help retailers to increase revenue and profitability resulting in optimize pricing, promotions, and customer retention."
            screenshot={IncreasedRevenue}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
        </Row>
      </section>

      <section>
        <Row>
          <h1 className={styles.retailCardsTitle}>
            Custom Solution to Empower Retailers
          </h1>
          <div className={styles.retailCards}>
            <Column.Wrap>
              <Column
                title="Agile Development"
                icon={CardOne}
                description={
                  "Modify or add new features to their applications in response to changing business needs or customer demands with ease."
                }
              />
              <Column
                title="Cost Savings"
                icon={CardTwo}
                description={
                  "Save costs by up to 90% compared to traditional development methods with Canonic. This saving can be invested in other areas of their business, such as marketing."
                }
              />
              <Column
                title="Data Integration"
                icon={CardThree}
                description={
                  "Integrate data from multiple sources, enabling you to gain insights into customer behavior, preferences, and buying patterns."
                }
              />
            </Column.Wrap>
          </div>
        </Row>
      </section>

      <CalloutWithDemoForm
        originPage="Canonic for Retail"
        id="get-a-demo"
        // className={styles.retailCallout}
      />
    </Layout>
  )
}

export default Retail
